import { useEffect, useState } from "react";
import "./App.css";
import GalleryContainer from "./GalleryContainer";
import ImageGallery from "./ImageGallery";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
}

function App() {

  const [images, setImages] = useState([]);
  const fecthImages = async () => {
    const req = await fetch(
      "https://jxjgsx3g4d.execute-api.me-central-1.amazonaws.com/default/RawabiImageUploader/list"
    );
    const images = await req.json();
    const newImages = images.filter(f => f.URL.indexOf(".pdf") === -1)
    const fullGalery = [...newImages]
    
    setImages(fullGalery);
  };

  useEffect(() => {
    fecthImages()
  },[])

  return (
    <div className="App">
      <div className="Rawabi-Container">
        <video className="Rawabi-VideoBG" src="./video_bg.mp4" muted autoPlay loop></video>
        
        <div className="Rawabi-Side">        
          <GalleryContainer maxSize={5} images={images} speed={25000} z={1} />
          <GalleryContainer maxSize={10} images={images} speed={20000} z={2} />          
        </div>
        {/* <div className="Rawabi-Center"></div>
        <div className="Rawabi-Side">
        <GalleryContainer maxSize={10} images={images} speed={32000} z={1} />
        <GalleryContainer maxSize={25} images={images} speed={28000} z={2} />
        </div> */}
      </div>
    </div>
  );
}

export default App;
