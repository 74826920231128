import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";
import { easings } from "@react-spring/web";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
}

// Gallery Container
const GalleryContainer = ( {speed, maxSize, z, images} ) => {
  const parentElm = useRef();
  shuffleArray(images)
  return (
    <div ref={parentElm} style={{ height: "100%", position: 'position', top:0, left:0, width: '100%', zIndex: z }}>
      {images.map((image, index) => (
        <Thumbnail parent={parentElm} speed={speed} maxSize={maxSize} index={index} key={index} data={image} total={images.length}/>
      ))}
    </div>
  );
};

// Thumbnail Component
const Thumbnail = ({ data, parent, index, speed, maxSize, total }) => {
  // Define the animation

  // Assuming random sizes for each thumbnail
  const parentParams = parent.current ? parent.current.getBoundingClientRect() : { width: 0, height: 0 };
  const minWidth = parentParams.width > 0 ? parentParams.width * 0.1 : 30;
  const maxWidth = parentParams.width > 0 ? parentParams.width * (maxSize / 100) : 30;
  
  const getRandomValues = () => {
    const width = Math.random() * (maxWidth - minWidth) + minWidth; // Random width between 100px and 150px
    const height = Math.random() * (maxWidth - minWidth) + minWidth;

    const left = Math.random() * (parseFloat(parentParams.width) - maxWidth); // 150 is assumed max width of thumbnail

    return {
      width,
      height,

      left,
    };
  };
  
  const randomValues = getRandomValues();
  
  const duration = Math.random() * (35000 - speed) + speed;
  const topThumb = Math.random() * (parseFloat(parentParams.height) - 1) - 1;
  const factor = Math.random() * (3500 - 2800) + 2800;
  const randomIndex = Math.random() * (total - 10) + 10;


  const animation = useSpring({
    from: { transform: `translateY(${parentParams.height + 20}px)` },
    to: { transform: `translateY(${0 - (maxWidth + 20)}px)` },
    loop: true,
    delay: (duration / 4) * index,
    config: {
      duration: duration
    }
  })

  const style = {
    position: "absolute",
    top: `0px`,
    left: `${randomValues.left}px`,
    height: `${maxWidth}px`,
  };

  return (
    <animated.div style={{ ...style, ...animation}}>
      <img
        src={data.URL}
        alt={data.Key}
        style={{
          height: "100%",
          objectFit: "cover",
          boxShadow: "0px 0px 5px rgba(0,0,0,.6)",
        }}
      />
    </animated.div>
  );
};

export default GalleryContainer;
